<template>
  <v-container fluid class="fill-height">
    <v-row no-gutters  align="center"
           justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form ref="loginForm" v-model="validLoginForm" @submit.prevent="login">
          <v-card elevation="6">
            <v-toolbar flat>
              <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.profile.authentication') }}</v-toolbar-title>
            </v-toolbar>
            <v-divider class="mb-2"/>
            <v-card-text class="py-1 px-3">
              <v-text-field
                prepend-icon="mdi-account"
                v-model="username"
                :rules="[rules.required, rules.email]"
                :label="$vuetify.lang.t('$vuetify.profile.email')"
                type="text"
                autocomplete="username"
                tabindex="1">
              </v-text-field>
              <v-text-field
                prepend-icon="mdi-lock-outline"
                v-model="password"
                :rules="[rules.required]"
                :label="$vuetify.lang.t('$vuetify.profile.password')"
                id="password"
                type="password"
                autocomplete="current-password"
                tabindex="2">
              </v-text-field>
            </v-card-text>
            <v-card-actions class="text-md-right text-xs-right px-3 pt-1 pb-3">
              <div class="flex-grow-1"></div>
              <router-link :to="{name: 'reset-password-message'}" tag="a" class="mr-3 subtitle-2 grey--text">Забули пароль?</router-link>
              <v-btn
                :loading="spinner('login')"
                :disabled="spinner('login') || !validLoginForm"
                type="submit"
                class="text-md-right"
                small
                color="success">{{ $vuetify.lang.t('$vuetify.profile.signin') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import {AUTH_REQUEST} from '@/store/modules/authentication/mutation-types';
  import {SPINNER_START, SPINNER_STOP} from '@/store/modules/spinner/mutation-types';
  import {VALIDATION_RULES} from '@/utils'
  export default {
    name: 'LoginView',
    data() {
      return {
        username: '',
        password: '',
        validLoginForm: false
      }
    },
    methods: {
      login() {
          if (this.$refs.loginForm.validate()) {
              const username = this.username.toLowerCase();
              const password = this.password;

              this.$store.commit(SPINNER_START, 'login');

              this.$store.dispatch(AUTH_REQUEST, {username, password})
                  .then(() => {
                      this.$router.push(this.$route.query.redirect ? {path: this.$route.query.redirect} : {name: 'home'});
                  })
                  .catch((err) => {
                      this.$snackbar(err, 'error')
                  })
                  .finally(() => {
                  this.$store.commit(SPINNER_STOP, 'login');
              });
          }

      }
    },
    computed: {
      ...mapGetters(['spinner']),
      rules() {
          return VALIDATION_RULES;
      },
    }
  }
</script>

<style scoped lang="scss">
</style>
